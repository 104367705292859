<template>
  <div>
    <b-table :fields="fields" :items="items" :current-page.sync="currentPage">
      <template #cell(cost)="row">
        {{ fees(row.item.publications[0]["publication_count"]) }}
      </template>
      <template #cell(link)="row">
        <b-link @click="downloadReceipt(row.item)"
          ><b-icon-archive></b-icon-archive
        ></b-link>
      </template>
    </b-table>
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-3"></b-pagination>
  </div>
</template>
<script>
import { axiosApiInst } from "../../plugins/axios";
import { createApi } from "../applications/api";
export default {
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  data() {
    return {
      fields: [
        { label: "Payment Ref", key: "payment_ref" },
        { label: "Amount", key: "cost" },
        { label: "Paid At", key: "paid_at" },
        { label: "Applicant name", key: "applicant.name" },
        { label: "Receipt", key: "link" },
      ],
      items: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const url = process.env.VUE_APP_BACKEND + "/payments";
      axiosApiInst.get(url,{
        params: {
          page: this.currentPage,
          per_page: this.perPage
        }
      }).then((response) => {
        this.items = response.data.data;
        this.currentPage = response.data.current_page;
        this.totalRows = response.data.total;
        this.perPage = response.data.per_page;
      });
    },

    fees(pubcount) {
      let start = pubcount;
      let total = 0;
      while (start > 0) {
        if (start >= 10) {
          start -= 10;
          total += 150000;
        } else if (start >= 5) {
          start -= 5;
          total += 100000;
        } else {
          total += 30000;
          start -= 1;
        }
      }
      return total;
    },
    downloadReceipt({ id, drn }) {
      const api = createApi("applications");
      api.getReceipt(id).then((src) => {
        var link = document.createElement("a");
        link.href = src;
        link.download = drn + ".jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
};
</script>